import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import HomeView from '../Views/Home';
import LoginView from '../Views/Login';
import OrdersView from '../Views/Orders'

const Router = () => {
    const auth = useSelector( state => state.auth );
    return (
        <Fragment>
            { auth.logged === 'NOT_LOGGED' ? ( <LoginView/> ) :
            <Switch>
                <Route exact path='/'><HomeView /></Route>
                <Route exact path='/orders'><OrdersView /></Route>
            </Switch>
            }
        </Fragment>
    );
};

export default Router;