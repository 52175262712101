import { APP_IS_OPEN_MENU, APP_IS_LOADER, APP_IS_OPEN_VIEW } from "../actions/app.action";
const defaultState = {
    isLoading: false,
    isOpenMenu: false,
    isOpenView: false,
};

const reducer = (state = defaultState, {type, payload}) => {
    switch (type) {
        case APP_IS_OPEN_MENU: {
            return { ...state, isOpenMenu: payload };
        }
        case APP_IS_LOADER: {
            return { ...state, isLoading: payload };
        }
        case APP_IS_OPEN_VIEW: {
            return { ...state, isOpenView: payload };
        }
        default: {
            return state;
        }
    };
};

export default reducer;