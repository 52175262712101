import { Avatar, Box, Divider, Drawer, IconButton, makeStyles, Typography } from "@material-ui/core";
import {
  ChevronRight as ChevronRightIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowRight as ArrowRightIcon,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { closeView } from "../../redux/actions/app.action";
import uberEatsLogo from '../../assets/svg/uber-eats.svg';
import justoLogo from '../../assets/svg/logo-justo.svg';
import peyaLogo from '../../assets/svg/pedidosya.svg';
import rappiLogo from '../../assets/svg/rappi.png';
import { TreeItem, TreeView } from "@material-ui/lab";
import { useState } from "react";
import { toAmount } from "../../utils/amount.util";

const drawerWidth = 600;

const getNameCustomer = (row) => {
  if (!row.orderData.aggregatorName) {
    return null;
  }
  if (row.orderData.aggregatorName.toUpperCase() !== 'RAPPI') {
    return row.orderData.customerFirstName + ' ' + row.orderData.customerLastName;
  } else {
    return row.orderData.orderId;
  }
}
const getLogo = (aggregatorName) => {
  if (!aggregatorName) {
    return null;
  }
  switch (aggregatorName.toUpperCase()) {
    case 'UBEREATS':
      return uberEatsLogo;
    case 'JUSTO':
      return justoLogo;
    case 'PEDIDOS YA':
      return peyaLogo;
    case 'RAPPI':
      return rappiLogo;
    default:
      return null;
  };
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  subtitle: {
    fontSize: 13,
    marginTop: 15,
    marginLeft: 20
  },
  title: {
    fontSize: 20,
    marginLeft: 35,
    fontWeight: "bold",
    marginBottom: 15
  },
  titleOrder: {
    fontSize: 18,
    marginLeft: 15,
    fontWeight: "bold"
  },
  avatar: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    fontSize: 13
  },
  root: {
    display: 'flex'
  },
  treeView: {
    marginTop: 15,
    marginBottom: 15,
    fontSize: 15
  },
  treeItemText: {
    marginLeft: 10,
    fontSize: 15
  },
  rootPayment: {
    width: 500,
  },
  paymentLeft: {
    fontSize: 15,
    width: 80,
    textAlign: "right",
    marginRight: 10,
    fontWeight: "bold"
  },
  paymentRight: {
    fontSize: 15,
    width: 80,
    textAlign: "right",
    fontWeight: "bold"
  }
}));
const SideBarRight = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const app = useSelector(state => state.app);
  const orders = useSelector(state => state.orders);
  const [expanded] = useState(() => {
    const index = [];
    const max = 30;
    for (let i = 0; i < max; i++) {
      index.push(String(i));
      for (let j = 0; j < max; j++) {
        index.push(`${i}_${j}`);
      }
    }
    return index;
  });
  // const handleToggle = (event, nodeIds) => {
  //   console.log(nodeIds);
  //   setExpanded(nodeIds);
  // };
  // useEffect( () => {

  // }, [expanded]);
  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={app.isOpenView}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={() => { dispatch(closeView()) }}>
          <ChevronRightIcon />
        </IconButton>
        <img
          alt="logo_app"
          style={{ maxWidth: 80, borderRadius: 4 }}
          src={getLogo(orders.orderData.aggregatorName)}
        />
        <Typography className={classes.titleOrder}>#{orders.orderData.orderId}</Typography>
      </div>
      <Divider />
      <Typography className={classes.subtitle}>Cliente</Typography>
      <Typography className={classes.title}>{getNameCustomer(orders)}</Typography>
      <Divider />
      <TreeView
        className={classes.treeView}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        // expanded={expanded}
        defaultExpanded={expanded}
      // onNodeToggle={handleToggle}
      >
        {orders.orderData?.orderAggregator?.cart?.items?.map((row, row_index) => { // Orden uberEats
          return (
            <TreeItem key={row_index} label={
              <div className={classes.root}>
                <Avatar className={classes.avatar}>{row.quantity}</Avatar>
                <Typography className={classes.treeItemText}>
                  {row.title}
                </Typography>
              </div>
            } nodeId={String(row_index)}>
              {row.selected_modifier_groups?.map((rowModifier, rowModifier_index) => {
                return (
                  <TreeItem key={rowModifier_index} label={
                    <div className={classes.root}>
                      <Typography className={classes.treeItemText}>
                        {rowModifier.title}
                      </Typography>
                    </div>
                  } nodeId={row_index + '_' + rowModifier_index}>
                    {rowModifier.selected_items?.map((rowSubItems, indexSubItems) => {
                      return (
                        <TreeItem key={indexSubItems} label={
                          <div className={classes.root}>
                            <Avatar className={classes.avatar}>{rowSubItems.quantity}</Avatar>
                            <Typography className={classes.treeItemText}>
                              {rowSubItems.title}
                            </Typography>
                          </div>
                        }></TreeItem>
                      )
                    })}
                  </TreeItem>
                )
              })}
            </TreeItem>
          );
        })}
        {orders.orderData?.orderInit?.data?.order?.items?.map((row, row_index) => { // Orden Justo
          return (
            <TreeItem key={row_index} label={
              <div className={classes.root}>
                <Avatar className={classes.avatar}>{1}</Avatar>
                <Typography className={classes.treeItemText}>
                  {row.product.name}
                </Typography>
              </div>
            } nodeId={String(row_index)}>
              {row.modifiers?.map((rowModifier, rowModifier_index) => {
                return (
                  <TreeItem key={rowModifier_index} label={
                    <div className={classes.root}>
                      <Typography className={classes.treeItemText}>
                        {rowModifier.name}
                      </Typography>
                    </div>
                  } nodeId={row_index + '_' + rowModifier_index}>
                    {rowModifier.options?.map((rowSubItems, indexSubItems) => {
                      return (
                        <TreeItem key={indexSubItems} label={
                          <div className={classes.root}>
                            <Avatar className={classes.avatar}>{1}</Avatar>
                            <Typography className={classes.treeItemText}>
                              {rowSubItems.name}
                            </Typography>
                          </div>
                        }></TreeItem>
                      )
                    })}
                  </TreeItem>
                )
              })}
            </TreeItem>
          );
        })}
        {orders.orderData?.orderInit?.details?.map((row, row_index) => { // Orden PedidosYa
          return (
            <TreeItem key={row_index} label={
              <div className={classes.root}>
                <Avatar className={classes.avatar}>{1}</Avatar>
                <Typography className={classes.treeItemText}>
                  {row.product.name}
                </Typography>
              </div>
            } nodeId={String(row_index)}>
              {row.optionGroups?.map((rowModifier, rowModifier_index) => {
                return (
                  <TreeItem key={rowModifier_index} label={
                    <div className={classes.root}>
                      <Typography className={classes.treeItemText}>
                        {rowModifier.name}
                      </Typography>
                    </div>
                  } nodeId={row_index + '_' + rowModifier_index}>
                    {rowModifier.options?.map((rowSubItems, indexSubItems) => {
                      return (
                        <TreeItem key={indexSubItems} label={
                          <div className={classes.root}>
                            <Avatar className={classes.avatar}>{1}</Avatar>
                            <Typography className={classes.treeItemText}>
                              {rowSubItems.name}
                            </Typography>
                          </div>
                        }></TreeItem>
                      )
                    })}
                  </TreeItem>
                )
              })}
            </TreeItem>
          );
        })}

        {orders.orderData?.orderInit?.order_detail?.items?.map((row, row_index) => { // Orden Rappi
          return (
            <TreeItem key={row_index} label={
              <div className={classes.root}>
                <Avatar className={classes.avatar}>{1}</Avatar>
                <Typography className={classes.treeItemText}>
                  {row.name}
                </Typography>
              </div>
            } nodeId={String(row_index)}>
              {row.subitems?.map((rowModifier, rowModifier_index) => {
                return (
                  <TreeItem key={rowModifier_index} label={
                    <div className={classes.root}>
                      <Typography className={classes.treeItemText}>
                        {rowModifier.name}
                      </Typography>
                    </div>
                  } nodeId={row_index + '_' + rowModifier_index}>
                  </TreeItem>
                )
              })}
            </TreeItem>
          );
        })}
      </TreeView>
      <Divider />
      <Box>
        <Box className={classes.rootPayment} display="flex" justifyContent="flex-end">
          <Typography className={classes.paymentLeft}>Total:</Typography>
          <Typography className={classes.paymentRight}>{toAmount(orders.orderData.amountOrder)}</Typography>
        </Box>
        <Box className={classes.rootPayment} display="flex" justifyContent="flex-end">
          <Typography className={classes.paymentLeft}>Impuesto:</Typography>
          <Typography className={classes.paymentRight}>{toAmount(orders.orderData.taxOrder)}</Typography>
        </Box>
        <Box className={classes.rootPayment} display="flex" justifyContent="flex-end">
          <Typography className={classes.paymentLeft}>Propina:</Typography>
          <Typography className={classes.paymentRight}>{toAmount(orders.orderData.tipOrder)}</Typography>
        </Box>
      </Box>
    </Drawer>
  );
};

export default SideBarRight;