import axios from 'axios';
import moment from 'moment';
import { closeLoader, openLoader } from './app.action';
export const GET_ALL_ORDERS = 'GET_ALL_ORDERS';
export const GET_ORDER_VIEW = 'GET_ORDER_VIEW';
export const NEW_NOTIFY_ORDER = 'NEW_NOTIFY_ORDER';

export const newOrderNotify = ( order ) => {
    return (dispatch, getState) => {
        try{
            const orders = getState().orders;
            let exists = false;
            let rows = orders.rows.filter(row => {
                if ( row._id === order._id ){
                    exists = true;
                    return false;
                } 
                return true;
            });
            let { nRows, rowsPage } = orders;
            
            if ( !exists ){
                nRows++;
            }
            rows.unshift({ load:false, ...order, ...{ maxTime: moment().add(5, 'seconds') } });

            dispatch({
                type: NEW_NOTIFY_ORDER,
                payload: {
                    rows, rowsPage, nRows
                }
            });
        }catch(err){
            console.log(err);
        }
    }
};

export const getOrderView = (orderId) => {
    return async (dispatch) => {
        try{
            const response = await axios.get(`/app/orders/${orderId}`);
            dispatch({
                type: GET_ORDER_VIEW,
                payload: {
                    orderId, 
                    orderData: response.data
                }
            });
        }catch(err){
            console.log(err);
        }finally{
            dispatch(closeLoader());
        }
    }
};

export const getAllOrders = ({ 
        page=1, rowsPage=30, restaurantId='', storeId=undefined, 
        dateFrom='2021-05-01', dateTo='2021-05-30', state=undefined, name=undefined, aggregatorId=undefined
    }) => {
    return async ( dispatch ) => {
        try{
            let filters = `from=${dateFrom}&to=${dateTo}&page=${page}&row=${rowsPage}`;
            if ( storeId ){
                filters+=`&storeId=${storeId}`;
            }
            if ( state ){
                filters+=`&state=${state}`;
            }
            dispatch(openLoader());
            const response = await axios.get(`/app/orders?${filters}`);
            dispatch({
                type: GET_ALL_ORDERS,
                payload: {
                    page, 
                    rowsPage, 
                    restaurantId, 
                    storeId, 
                    dateFrom, 
                    dateTo, 
                    state, 
                    name, 
                    aggregatorId,
                    rows: response.data.data,
                    nRows: response.data.count    
                }
            });
        }catch(err){
            console.log(err);
        }finally{
            dispatch(closeLoader());
        }
    }
};