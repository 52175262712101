import { AppBar, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, Toolbar, Typography, Drawer, Grid, Avatar } from '@material-ui/core';
import {
    Home as HomeIcon,
    Menu as MenuIcon,
    ChevronLeft as ChevronLeftIcon,
    Assignment as AssignmentIcon,
    ExitToApp as ExitToAppIcon,
  } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import clsx from 'clsx';
import { openMenuAction, closeMenuAction } from '../../redux/actions/app.action';
import { NavLink } from 'react-router-dom';
import SideBarRight from './SideBar';
import { closeAuth } from '../../redux/actions/auth.action';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // padding: theme.spacing(1),
    marginLeft: -drawerWidth,
    // marginLeft: theme.spacing(2),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  navLink: {
    textDecoration: "none"
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.5),
    fontWeight: 'bold',
  }
}));
const items = [
    {
      text: 'Inicio',
      path: '/',
      icon: <HomeIcon />,
    },
    {
      text: 'Órdenes',
      path: '/orders',
      icon: <AssignmentIcon />
    }
]

const NavBar = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const app = useSelector( state => state.app );
    const auth = useSelector( state => state.auth );
    const closeApp = () => {
      dispatch(closeAuth());
    };
    return (
        <div className={classes.root}>
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: app.isOpenMenu,
            })}
          >
            <Toolbar>
              <Grid container>
                <Grid item xs={2} container direction="row" justify="center">
                  <Grid item xs={2}>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={() => { dispatch(openMenuAction()) }}
                      edge="start"
                      className={clsx(classes.menuButton, app.isOpenMenu && classes.hide)}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="h6" style={{paddingTop:10}}>
                      LivePOS
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={8} container direction="row" justify="center"></Grid>
                <Grid item xs={2} container direction="row" justify="center">
                  <Grid item xs={10} container justify="flex-end" alignItems="center">
                    <Grid item xs={9}>
                    { `${auth.firstName} ${auth.lastName}`}
                    </Grid>
                    <Grid item xs={3}>
                      <Avatar className={classes.avatar}>{auth.firstName.charAt(0)+auth.lastName.charAt(0)}</Avatar>
                    </Grid>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton color="inherit"
                      aria-label="open drawer"
                      onClick={() => {closeApp()}}
                      style={{float: 'right'}}
                    >
                      <ExitToAppIcon/>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={app.isOpenMenu}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={() => { dispatch(closeMenuAction()) }}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>
              {items.map(( item, index ) => (
                <NavLink onClick={() => { dispatch(closeMenuAction()) }}exact={true} to={item.path} key={`nav_menu_${index}`} className={classes.navLink}>
                  <ListItem button key={index}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText>{item.text}</ListItemText>
                  </ListItem>
                </NavLink>
              ))}
            </List>
            <Divider />
          </Drawer>
          <SideBarRight />
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: app.isOpenMenu,
            })}
          >
            <div className={classes.drawerHeader} />
          </main>
        </div>
      );
};

export default NavBar;