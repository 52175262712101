import SocketIO from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';
import { createContext, useMemo } from 'react';
import { newOrderNotify } from '../redux/actions/orders.action';

const SocketContext = createContext();

export const SocketContextProvider = (props) => {
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const socket = SocketIO(process.env.REACT_APP_API_URL, {
        transports: ['websocket'],
        path: '/socket.io',
        forceNew: true
    });
    const value = useMemo(() => {
        return { socket };
    }, [socket]);
    socket.on('connect', () => {
        console.log(`Socket connected.`);
    });
    socket.on('disconnect', (reason) => {
        console.log(`Disconnected: ${reason}`);
    });
    socket.on(`${auth.userId}_SOCKET_NEW_ORDER`, (data) => {
        dispatch(newOrderNotify(data));
    });

    return <SocketContext.Provider value={value} {...props} />;
};