import 'date-fns';
import Chip from '@material-ui/core/Chip';
import { Divider, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders } from "../../../redux/actions/orders.action";
import moment from 'moment';
import { Description as DescriptionIcon } from "@material-ui/icons";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { openView } from '../../../redux/actions/app.action';
import uberEatsLogo from '../../../assets/svg/uber-eats.svg';
import justoLogo from '../../../assets/svg/logo-justo.svg';
import rappiLogo from '../../../assets/svg/rappi.png';
import peyaLogo from '../../../assets/svg/pedidosya.svg';
import jrLogo from '../../../assets/johnny_rockets.webp';
import { Skeleton } from '@material-ui/lab';
import { toAmount } from '../../../utils/amount.util';

const useStyles = makeStyles((theme) => ({
    content: {
        margin: theme.spacing(10),
        // width: theme.spacing(90)
    },
    TableContainer: {
        marginTop: '30px'
    },
    datePicker: {
        marginLeft: theme.spacing(5)
    },
    channel: {
        align: 'center',
        width: '14%'
    },
    customer: {
        align: 'center',
        width: '24%'
    },
    store: {
        align: 'center',
        width: '14%'
    },
    date: {
        align: 'center',
        width: '14%'
    },
    amount: {
        align: 'right',
        width: '14%'
    },
    state: {
        align: 'center',
        width: '14%'
    },
    view: {
        align: 'center',
        width: '4%'
    }
}));
const getLogo = (aggregatorName) => {
    switch (aggregatorName.toUpperCase()) {
        case 'UBEREATS':
            return uberEatsLogo;
        case 'JUSTO':
            return justoLogo;
        case 'PEDIDOS YA':
            return peyaLogo;
        case 'RAPPI':
            return rappiLogo;
        default:
            return null;
    };
};
const formatDate = 'YYYY-MM-DD';
const Orders = () => {
    const classes = useStyles();
    const orders = useSelector(state => state.orders);
    const [page, setPage] = React.useState(0);
    const [rowsPage, setRowsPage] = useState(10);
    const [dateFrom, setDateFrom] = useState(moment().format(formatDate));
    const [dateTo, setDateTo] = useState(moment().format(formatDate));
    const dispatch = useDispatch();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPage(event.target.value);
        setPage(1);
    };
    const handleDateFromChange = (event) => {
        setDateFrom(moment(event).format(formatDate));
    };
    const handleDateToChange = (event) => {
        setDateTo(moment(event).format(formatDate));
    };
    useEffect(() => {
        dispatch(getAllOrders({ page: page + 1, dateFrom: moment(dateFrom).format('YYYY-MM-DD'), dateTo: moment(dateTo).format('YYYY-MM-DD'), rowsPage }));
    }, [dateTo, dateFrom, page, rowsPage, dispatch]);

    return (
        <Paper className={classes.content}>
            <img src={jrLogo} width='80px' alt='logo' />
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    className={classes.datePicker}
                    disableToolbar
                    variant="inline"
                    format="DD-MM-YYYY"
                    margin="normal"
                    id="date-picker-inline-from"
                    label="Desde"
                    value={dateFrom}
                    onChange={handleDateFromChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    autoOk={true}
                />
                <KeyboardDatePicker
                    className={classes.datePicker}
                    disableToolbar
                    variant="inline"
                    format="DD-MM-YYYY"
                    margin="normal"
                    id="date-picker-inline-to"
                    label="Hasta"
                    value={dateTo}
                    onChange={handleDateToChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    autoOk={true}
                />
            </MuiPickersUtilsProvider>
            <Divider />
            <TableContainer className={classes.TableContainer}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.channel}>Canal</TableCell>
                            <TableCell className={classes.customer}>Nombre Cliente</TableCell>
                            <TableCell className={classes.store}>Sucursal</TableCell>
                            <TableCell className={classes.date}>Fecha</TableCell>
                            <TableCell className={classes.amount}>Monto</TableCell>
                            <TableCell className={classes.state}>Estado</TableCell>
                            <TableCell className={classes.view}>Ver</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders.rows.map((row, index) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={index} bgcolor={row.load ? 'warning.main' : (row.maxTime && moment().diff(row.maxTime, 'seconds') < 0 ? (row.status === 'ORDER_INSERT' ? 'success.main' : 'error.main') : '')}>
                                    <TableCell className={classes.channel}>
                                        {row.load ?
                                            <Skeleton variant="text" /> :
                                            <img alt="logo_app" style={{ maxWidth: 100, borderRadius: 4 }} src={getLogo(row.aggregatorName)} />
                                        }
                                    </TableCell>
                                    <TableCell className={classes.customer}>
                                        {row.load ?
                                            <Skeleton variant="text" /> :
                                            row.aggregatorName.toUpperCase() !== 'RAPPI' ? row.customerFirstName + ' ' + row.customerLastName : row.orderId
                                        }
                                    </TableCell>
                                    <TableCell className={classes.store}>
                                        {row.load ?
                                            <Skeleton variant="text" /> :
                                            row.restaurantName + ' - ' + row.storeName
                                        }
                                    </TableCell>
                                    <TableCell className={classes.date}>
                                        {row.load ?
                                            <Skeleton variant="text" /> : row.dateOrder
                                        }
                                    </TableCell>
                                    <TableCell className={classes.amount}>
                                        {row.load ?
                                            <Skeleton variant="text" /> :
                                            `$ ${toAmount(row.amountOrder)}`
                                        }
                                    </TableCell>
                                    <TableCell className={classes.status}>
                                        {row.load ?
                                            <Skeleton variant="text" /> :
                                            row.status === 'ORDER_INSERT' ?
                                                <Chip
                                                    label="Ingresada"
                                                    color="primary"
                                                /> :
                                                row.status === 'ORDER_CANCEL' ?
                                                    <Chip
                                                        label="Cancelada"
                                                        color="error"
                                                    /> :
                                                    <Chip
                                                        label="Error, no ingresada"
                                                        color="secondary"
                                                    />
                                        }
                                    </TableCell>
                                    <TableCell className={classes.view}>
                                        {row.load ?
                                            <Skeleton variant="text" /> :
                                            <IconButton onClick={() => { dispatch(openView(row._id)) }}>
                                                <DescriptionIcon />
                                            </IconButton>
                                        }
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={orders.nRows}
                rowsPerPage={rowsPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default Orders;