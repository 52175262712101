import { GET_ALL_ORDERS, GET_ORDER_VIEW, NEW_NOTIFY_ORDER } from "../actions/orders.action";


const defaultState = {
    page: 1,
    rowsPage: 10,
    restaurantId: null,
    storeId: null,
    dateFrom: null,
    dateTo: null,
    state: null,
    name: null,
    aggregatorId: null,
    rows: [],
    nRows: 0,
    orderId: null,
    orderData: {},
};

const reducer = (state = defaultState, { type, payload }) => {
    switch (type){
        case GET_ALL_ORDERS: {
            return { ...state, ...payload };
        }
        case GET_ORDER_VIEW: {
            return { ...state, ...payload };
        }
        case NEW_NOTIFY_ORDER: {
            return { ...state, ...payload };
        }
        default: {
            return state;
        }
    }
};

export default reducer;