export const toAmount = (nStr) => {
    try{
        /*
        let valueTemp = value;
        let dataReturn = "";
        let mult = 1;
        if ( valueTemp < 0 ){
            mult = -1;
            valueTemp*=-1;
        }
        while ( valueTemp > 0 ){
            let mil = valueTemp%1000;
            valueTemp=parseInt(valueTemp/1000, 10);
            console.log(valueTemp)
            if ( dataReturn.length === 0 ){
                dataReturn = mil;
            }else{
                dataReturn = mil + '.' + dataReturn;
            }
        }
        if ( mult < 0 ){
            return "-" + dataReturn;
        }
        return dataReturn;
        */
        nStr += '';
        var x = nStr.split('.');
        var x1 = x[0];
        var x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
                x1 = x1.replace(rgx, `$1.$2`);
        }
        return x1 + x2;
    }catch(err){
        console.log(err);
    }
}