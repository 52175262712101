import axios from 'axios';
import { closeLoader, openLoader } from './app.action';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_STATUS = 'AUTH_LOGIN_STATUS';
export const AUTH_LOGIN_CLOSE = 'AUTH_LOGIN_CLOSE';

const loginError = () => {
    return (dispatch) => {
        dispatch({
            type: AUTH_LOGIN_STATUS,
            payload: 'ERROR'
        });
    };
};

const loginSuccess = () => {
    return (dispatch) => {
        dispatch({
            type: AUTH_LOGIN_STATUS,
            payload: 'OK'
        });
    };
};

export const closeAuth = () => {
    return (dispatch) => {
        try{
            axios.defaults.headers.common.authorization = null;
            dispatch({
                type: AUTH_LOGIN_CLOSE
            });
            window.localStorage.clear();
        }catch(err){
            console.log(err);
        }
    }
}
export const loginAuth = ({ email, password }) => {
    return async ( dispatch ) => {
        try{
            dispatch(openLoader());
            const response = await axios.post('/login', {email, password});
            if ( !response.data.token ){
                dispatch(loginError());
                dispatch({ type: 'AUTH_LOGIN_FAIL' });
                return;
            }
            dispatch(loginSuccess());
            window.localStorage.setItem("token", response.data.token);
            axios.defaults.headers.common.authorization = `${response.data.token}`;
            dispatch({
                type: AUTH_LOGIN_SUCCESS,
                payload: {
                    logged: 'LOGGED',
                    userId: response.data.user._id,
                    firstName: response.data.user.firstName,
                    lastName: response.data.user.lastName,
                    profileId: response.data.user.profileId
                }
            });
        }catch(err){
            dispatch(loginError());
            console.log(err);
        }finally{
            dispatch(closeLoader());
        }
    }
};
