import { Button, Grid, InputAdornment, makeStyles, Paper, TextField } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { loginAuth } from '../../../redux/actions/auth.action';
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles(({ breakpoints, spacing, zIndex }) => ({
    main: {
        width: "auto",
        display: "block", // Fix IE 11 issue.
        marginLeft: spacing(3),
        marginRight: spacing(3),
        [breakpoints.up(400 + spacing(6))]: {
            width: 400,
            marginLeft: "auto",
            marginRight: "auto",
        },
        borderRadius: "6px",
    },
    paper: {
        marginTop: spacing(18),
        marginBottom: spacing(10),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: `${spacing(2)}px ${spacing(3)}px ${spacing(3)}px`,
        borderRadius: "8px",
    },
    // backdrop: {
    //     zIndex: zIndex.drawer+1,
    //     color: '#fff'
    // }
}));

const Login = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const dispatch = useDispatch();
    const auth = useSelector( state => state.auth );
    const updateData = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
    };
    const styles = useStyles();
    return (
        <>
            <main className={styles.main}>
                <Paper className={styles.paper}>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <TextField
                                value={formData.email}
                                error={auth.loginStatus === "ERROR"}
                                name="email"
                                label="E-Mail"
                                autoComplete="email"
                                autoFocus
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                onChange={updateData}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment>
                                    <AccountCircleIcon />
                                    </InputAdornment>
                                ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={formData.password}
                                error={auth.loginStatus === "ERROR"}
                                name="password"
                                type="password"
                                label="Password"
                                variant="outlined"
                                autoComplete="current-password"
                                onChange={updateData}
                                margin="dense"
                                fullWidth
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment>
                                    <VpnKeyIcon/>
                                    </InputAdornment>
                                ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                color="primary"
                                variant={
                                    auth.loginStatus === "READY" ? "contained" : "outlined"
                                }
                                onClick={() => dispatch(loginAuth({ ...formData }))}
                                startIcon={<ExitToAppIcon />}
                            >Entrar</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </main>
        </>
    );
}

export default Login;