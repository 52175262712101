import { AUTH_LOGIN_SUCCESS, AUTH_LOGIN_STATUS, AUTH_LOGIN_CLOSE } from "../actions/auth.action";


const defaultState = {
    logged: 'NOT_LOGGED',
    loginStatus: 'OK',
    userId: '',
    firstName: '',
    lastName: '',
    profileId: ''
};

const reducer = (state = defaultState, { type, payload }) => {
    switch (type){
        case AUTH_LOGIN_SUCCESS: {
            console.log(payload);
            return { ...state, ...payload };
        }
        case AUTH_LOGIN_STATUS: {
            return { ...state, loginStatus: payload };
        }
        case AUTH_LOGIN_CLOSE:{
            return defaultState
        }
        default: {
            return state;
        }
    }
};

export default reducer;