import './App.css';
import React from "react";
import { Backdrop, CircularProgress, CssBaseline, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import Router from './components/Router';
import axios from 'axios';
import NavBar from './components/Frames/NavBar';
import { SocketContextProvider } from './context/Socket';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const token = window.localStorage.getItem('token');

if ( token && token !== null ){
  axios.defaults.headers.common.authorization = token;
}

const useStyles = makeStyles(({ zIndex }) => ({
  backdrop: {
    zIndex: zIndex.drawer+1,
    color: '#fff'
  }
}));

const App = () => {
  const auth = useSelector((state) => state.auth);
  const app = useSelector( state => state.app );
  const styles = useStyles();
  return (
    <div>
      <SocketContextProvider>
        <CssBaseline />
        {auth.logged === "LOGGED" && <NavBar />}
        <Backdrop className={styles.backdrop} open={app.isLoading}><CircularProgress color="inherit"/></Backdrop>
        <Router />
      </SocketContextProvider>
    </div>
  );
};

export default App;