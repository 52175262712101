import { getOrderView } from "./orders.action";

export const APP_IS_OPEN_MENU = 'APP_IS_OPEN_MENU';
export const APP_IS_LOADER = 'APP_IS_LOADER';
export const APP_IS_OPEN_VIEW = 'APP_IS_OPEN_VIEW';

export const openView = (orderId) => {
    return ( dispatch ) => {
        try{
            dispatch(openLoader());
            dispatch(getOrderView(orderId));
            dispatch({ type: APP_IS_OPEN_VIEW, payload: true });
        }catch(err){
            console.log(err);
        }
    }
};

export const closeView = () => {
    return ( dispatch ) => {
        try{
            dispatch({ type: APP_IS_OPEN_VIEW, payload: false });
        }catch(err){
            console.log(err);
        }
    }
};

export const openLoader = () => {
    return (dispatch) => {
        try{
            dispatch({ type: APP_IS_LOADER, payload: true });
        }catch(err){
            console.log(err);
        }
    };
};

export const closeLoader = () => {
    return (dispatch) => {
        try{
            dispatch({ type: APP_IS_LOADER, payload: false });
        }catch(err){
            console.log(err);
        }
    };
};

export const openMenuAction = () => {
    return (dispatch) => {
       try{
            dispatch({ type: APP_IS_OPEN_MENU, payload: true });
        }catch(err){
            console.log(err);
        }
    };
};

export const closeMenuAction = () => {
    return (dispatch) => {
        try{
            dispatch({ type: APP_IS_OPEN_MENU, payload: false });
        }catch(err){
            console.log(err);
        }
    };
};
