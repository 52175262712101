import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    content: {
        margin: theme.spacing(10)
    }
}));
const Home = () => {
    const classes = useStyles();
    return (
        <div className={classes.content}>
            <h1>Bienvenido a LivePOS</h1>
        </div>
    );
};

export default Home;