import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { routerMiddleware, connectRouter } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension";
import ReduxThunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import app from "./reducers/app.reducer";
import auth from "./reducers/auth.reducer";
import orders from "./reducers/orders.reducer";
import { createBrowserHistory } from "history";

const createRootReducer = (history) =>
  combineReducers({
    app,
    auth,
    orders,
    router: connectRouter(history),
  });

// * -------- Estado inicial de la aplicación
const initialState = {};

// * -------- Middlewares
export const history = createBrowserHistory();
const middlewares = [ReduxThunk, routerMiddleware(history)];

// * -------- Enhancers del store --------
const enhancers = [];

// * -------- Componer Middlewares y Enhancers --------
let composedEnhancers = null;
if (
  process.env.NODE_ENV === "development" ||
  process.env.NODE_ENV === "staging"
) {
  const composeEnhancers = composeWithDevTools({ trace: true });
  composedEnhancers = composeEnhancers(
    applyMiddleware(...middlewares),
    ...enhancers
  );
} else {
  composedEnhancers = compose(applyMiddleware(...middlewares), ...enhancers);
}

// * -------- Configuración del Persist --------
const persistConfig = {
  key: "root",
  storage,
  blacklist: [""],
  whitelist: ["app", "auth", "profile"],
};

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);

export default function configureStore() {
  const store = createStore(persistedReducer, initialState, composedEnhancers);
  const persistor = persistStore(store);
  return { store, persistor };
}
